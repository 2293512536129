export const getYoutubeId = (youtubeVideoLink, bannerType) => {
  let youtubeId = youtubeVideoLink;
  if (bannerType === "youtube") {
    if (youtubeId?.includes("youtu.be")) {
      youtubeId = youtubeId.split("youtu.be/")[1].split("?")[0];
    } else {
      if (youtubeId?.includes("v=")) {
        youtubeId = youtubeId.split("v=")[1];
        if (youtubeId?.includes("&")) {
          youtubeId = youtubeId.split("&")[0];
        }
      }
    }
  }
  return youtubeId;
};

export const getVideoUrl = (mp4VideoFile, bannerType) => {
  let videoUrl;
  const publicURL = mp4VideoFile?.localfile?.publicURL;
  if (bannerType === "mp4") {
    if (publicURL) {
      videoUrl = publicURL;
    } else {
      videoUrl = mp4VideoFile?.mediaItemUrl;
    }
  }
  return videoUrl;
};
