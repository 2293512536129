import React from "react";
import convertId from "../../utilities/convert-id";
import { graphql } from "gatsby";
import Content from "../utilities/content";

const TwoColumnsContent = ({ data }) => {
  return (
    <section
      id={convertId(data.name)}
      className="two-columns-content section section-b-0"
    >
      <div className="container">
        <Content className="title">{data.title}</Content>
        <div className="row">
          <Content className="col-lg-6 pe-lg-5 first-column">
            {data.firstColumn}
          </Content>
          <Content className="col-lg-6 second-column">
            {data.secondColumn}
          </Content>
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment TwoColumnsContent on WpPage_Sections_Sections_TwoColumnsContent {
    name
    fieldGroupName
    title
    firstColumn
    secondColumn
  }
`;

export default TwoColumnsContent;
