// ACF LINK Relation
const convertLink = (link) => {
  if (link === null) return "";

  return link.url.includes(process.env.WP_URL)
    ? link.url.replace(process.env.WP_URL, "")
    : link.url;
};

export default convertLink;
