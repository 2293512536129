// Handle Advanced Custom Field's (WordPress Plugin) link field type.

import React from "react";
import convertLink from "../../utilities/convert-link";
import Link from "./link";

const CmsLink = ({ link, arrow, children, ...other }) => {
  if (link === null) return "";

  let finalUrl = convertLink(link);

  if (link.target && !other["target"]) other["target"] = link.target;
  return (
    <Link to={finalUrl} {...other}>
      {children ? children : link.title}
    </Link>
  );
};

export default CmsLink;
