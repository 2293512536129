import React from "react";
import convertId from "../../utilities/convert-id";
import { graphql } from "gatsby";

import CmsLink from "../utilities/cms-link";
import Content from "../utilities/content";
import CmsImage from "../utilities/cms-image";

const ContentCta = ({ data }) => {
  return (
    <section
      id={convertId(data.name)}
      className="section-cta cta1 section-content-cta"
    >
      {data.backgroundImage && (
        <CmsImage className="section-img" image={data.backgroundImage} />
      )}

      <div className="container-fluid" data-aos="fade-up">
        <div className="row">
          <div className="col-lg-6 mb-4 mb-lg-0">
            <Content className="title">{data.title}</Content>
          </div>
          <div className="col-lg-6">
            <Content className="content">{data.content}</Content>
            <CmsLink link={data.buttonLink} className="btn btn-primary" />
          </div>
        </div>
      </div>
      <div className="section-cta-overlay"></div>
    </section>
  );
};

export const query = graphql`
  fragment ContentCta on WpPage_Sections_Sections_ContentCta {
    fieldGroupName
    name
    content
    title
    buttonLink {
      target
      title
      url
    }
    backgroundImage {
      altText
      localFile {
        childImageSharp {
          gatsbyImageData
        }
        extension
        publicURL
      }
    }
  }
`;

export default ContentCta;
