import React, { useEffect, useState } from "react";
import L from "leaflet";
import {
  MapContainer,
  TileLayer,
  Marker,
  useMapEvents,
  Tooltip,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import icon from "../../images/marker-icon.png";

const ProjectCard = ({
  project,
  total,
  index,
  changeProjectCard,
  closeProjectCard,
}) => (
  <div className="pmp-card">
    <div className="pmpc-intro">
      <div className="pmpc-intro-txt">
        {index + 1} of {total}
      </div>
      <div
        className="pmpc-intro-txt pmpc-intro-close"
        onClick={() => closeProjectCard()}
      >
        X Close
      </div>
    </div>
    <div className="pmpc-info">
      <h4 className="pmpc-info-title h2">{project.projectName}</h4>
      <hr />
      <div className="pmpc-info-link">
        <a href={`/project/${project.id}`}>View This Project »</a>
      </div>
      <h5 className="pmpc-info-overview h6">Project Overview</h5>
      <ul className="pmpc-info-ul">
        {project.sportType && (
          <li>
            <strong>Sport Type:</strong>
            <span>{project.sportType}</span>
          </li>
        )}
        {project.projectType && (
          <li>
            <strong>Project Type:</strong>
            <span>{project.projectType}</span>
          </li>
        )}
        {project.product && (
          <li>
            <strong>Products Used:</strong>
            <span>{project.product}</span>
          </li>
        )}
      </ul>
    </div>
    {total > 1 && (
      <div className="pmpc-arrows">
        <div className="pmpc-arrow" onClick={() => changeProjectCard(-1)}>
          « Previous<span className="pmpc-arrow-text"> Project</span>
        </div>
        <div className="pmpc-arrow" onClick={() => changeProjectCard(1)}>
          Next<span className="pmpc-arrow-text"> Project</span> »
        </div>
      </div>
    )}
  </div>
);

const InsideMap = ({
  projects,
  initMapPos,
  initMapCenter,
  handleMarkerClick,
}) => {
  const map = useMapEvents({
    moveend() {
      saveMapView();
    },
  });

  const saveMapView = () => {
    let mapCenter = map.getCenter();
    localStorage.setItem(
      "mapView",
      mapCenter.lat + "," + mapCenter.lng + "," + map.getZoom()
    );
  };

  const handleResize = () => {
    let mapView = localStorage.getItem("mapView");
    if (mapView) {
      let mapViewArr = mapView.split(",");
      map.setView([mapViewArr[0], mapViewArr[1]], mapViewArr[2]);
      return;
    }

    let windowWidth = window.innerWidth;

    /* @TODO The values used here for map position could be adjusted */
    if (windowWidth < 576) {
      map.setView([39.36, -97.2], 3);
    } else if (windowWidth < 768) {
      map.setView([36.75, initMapCenter["x"]], 3);
    } else if (windowWidth < 992) {
      map.setView([36.75, -98], 4);
    } else if (windowWidth < 1400) {
      map.setView([36.75, initMapCenter["x"]], 4);
    } else {
      map.setView(initMapPos["center"], initMapPos["zoom"]);
    }
  };

  useEffect(() => {
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  return (
    <>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {projects.map((projectSet) => {
        return (
          projectSet.latitude &&
          projectSet.longitude && (
            <Marker
              position={[projectSet.latitude, projectSet.longitude]}
              key={projectSet.key}
              eventHandlers={{
                click: (e) => {
                  handleMarkerClick(projectSet.projects);
                },
              }}
            >
              {projectSet.projects.length > 0 && (
                <Tooltip>
                  <div className="tooltip-project">
                    <h4 className="mb-0 default-h">
                      {projectSet.projects[0].projectName}
                    </h4>
                    <h6 className="mb-0 default-h text-primary">
                      {projectSet.projects[0].city}
                      {projectSet.projects[0].city &&
                        projectSet.projects[0].state &&
                        ", "}
                      {projectSet.projects[0].state}
                    </h6>
                  </div>
                </Tooltip>
              )}
            </Marker>
          )
        );
      })}
    </>
  );
};

const ProjectMap = ({ projects }) => {
  const [activeProjectSet, setActiveProjectSet] = useState({
    show: false,
    activeIndex: 0,
    projects: [],
  });

  if (typeof window === "undefined") return;

  let customIcon = L.icon({
    iconUrl: icon,
    shadowUrl: null,
    iconSize: [45, 45],
    iconAnchor: [22.5, 45],
  });

  L.Marker.prototype.options.icon = customIcon;

  const initMapCenter = {
    y: 37.25,
    x: -97,
  };

  const initMapPos = {
    center: [initMapCenter["y"], initMapCenter["x"]],
    zoom: 5,
  };

  const changeProjectCard = (adjustBy) => {
    let newActiveIndex = activeProjectSet.activeIndex + adjustBy;
    if (newActiveIndex < 0) {
      newActiveIndex = activeProjectSet.projects.length - 1;
    } else if (newActiveIndex >= activeProjectSet.projects.length) {
      newActiveIndex = 0;
    }

    setActiveProjectSet({
      ...activeProjectSet,
      activeIndex: newActiveIndex,
    });
  };

  const closeProjectCard = () => {
    setActiveProjectSet({
      ...activeProjectSet,
      show: false,
    });
  };

  const handleMarkerClick = (projects) => {
    if (activeProjectSet.show && projects[0] === activeProjectSet.projects[0]) {
      closeProjectCard();

      return;
    }

    setActiveProjectSet({
      show: true,
      activeIndex: 0,
      projects: projects,
    });
  };

  let projectsData = [];

  projects.forEach((project) => {
    let projectCordKey = project.latitude + "-" + project.longitude;

    if (!projectsData[projectCordKey]) {
      projectsData[projectCordKey] = {
        key: projectCordKey,
        latitude: project.latitude,
        longitude: project.longitude,
        projects: [project],
      };

      return;
    }

    projectsData[projectCordKey].projects = [
      ...projectsData[projectCordKey].projects,
      project,
    ];
  });

  projectsData = Object.values(projectsData);

  return (
    <>
      <div className="project-map-holder">
        <MapContainer
          center={initMapPos["center"]}
          zoom={initMapPos["zoom"]}
          scrollWheelZoom={true}
          preferCanvas={true}
        >
          <InsideMap
            projects={projectsData}
            initMapPos={initMapPos}
            initMapCenter={initMapCenter}
            handleMarkerClick={handleMarkerClick}
          />
        </MapContainer>
        {activeProjectSet.projects.length > 0 && (
          <div
            className={`project-map-popup ${
              activeProjectSet.show ? "active" : ""
            } ${activeProjectSet.projects.length > 2 ? 'pmp-length-many' : 'pmp-length-' + activeProjectSet.projects.length}`}
          >
            <ProjectCard
              project={activeProjectSet.projects[activeProjectSet.activeIndex]}
              total={activeProjectSet.projects.length}
              index={activeProjectSet.activeIndex}
              changeProjectCard={changeProjectCard}
              closeProjectCard={closeProjectCard}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default ProjectMap;
