import React from "react";
import convertId from "../../utilities/convert-id";
import { graphql } from "gatsby";
import Content from "../utilities/content";
import CmsLink from "../utilities/cms-link";
import CmsImage from "../utilities/cms-image";

const HomeHero = ({ data }) => {
  return (
    <section id={convertId(data.name)} className="home-hero">
      {data.selectBackground === "video" && (
        <div className="bg-wrapper">
          {data.backgroundVideo && (
            <video autoPlay loop playsInline muted className="bg-video">
              <source
                src={data.backgroundVideo.mediaItemUrl}
                type="video/mp4"
              />
            </video>
          )}
          <div className="bg-overlay"></div>
        </div>
      )}

      {data.selectBackground === "image" && (
        <CmsImage className="section-img" image={data.backgroundImage} />
      )}

      <div className="container">
        <Content className="hero-title mb-4 mb-lg-0">{data.content}</Content>
        <div className="home-hero-btns">
          {data.buttons &&
            data.buttons.map((button, key) => (
              <div className="text-lg-start mb-4" key={key}>
                <CmsLink
                  className="btn btn-primary"
                  link={button.button}
                ></CmsLink>
              </div>
            ))}
        </div>
      </div>

      <div className="home-hero-services d-none d-lg-block">
        <div className="content">
          <Content className="text-center text-primary text-uppercase fw-bold">
            {data.linksTitle}
          </Content>

          <ul className="nav flex-column services-list">
            {data.links &&
              data.links.map((link, key) => (
                <li className="nav-item hsb" key={key}>
                  <CmsLink className="nav-link" link={link.link}></CmsLink>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment HomeHero on WpPage_Sections_Sections_HomeHero {
    fieldGroupName
    name
    content
    buttons {
      button {
        target
        title
        url
      }
    }
    linksTitle
    links {
      link {
        target
        title
        url
      }
    }
    selectBackground

    backgroundVideo {
      altText
      mediaItemUrl
      localFile {
        extension
        publicURL
        childImageSharp {
          gatsbyImageData
        }
      }
    }
    backgroundImage {
      altText
      localFile {
        childImageSharp {
          gatsbyImageData
        }
        extension
        publicURL
      }
    }
  }
`;

export default HomeHero;
