import React from "react";
import convertId from "../../utilities/convert-id";
import { graphql } from "gatsby";
import CmsImage from "../utilities/cms-image";
import Content from "../utilities/content";

const TitleWithImageOnRight = ({ data }) => {
  return (
    <section
      id={convertId(data.name)}
      className="page-title section-title-with-image-on-right"
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-6 pe-lg-5">
            <Content className="title">{data.title}</Content>
            {data.rightDisplay === "image" && (
              <Content className="content-left">{data.content}</Content>
            )}
          </div>
          <div className="col-lg-6">
            <div className="page-title-content">
              {data.rightDisplay === "image" && data.image && (
                <CmsImage
                  image={data.image}
                  alt={data.image.altText}
                  className="img-fluid title-image"
                />
              )}
              {data.rightDisplay === "content" && (
                <Content className="content-right p-3 p-lg-6">{data.content}</Content>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment TitleWithImageOnRight on WpPage_Sections_Sections_TitleWithImageOnRight {
    fieldGroupName
    name
    title
    rightDisplay
    content
    image {
      altText
      localFile {
        extension
        publicURL
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  }
`;

export default TitleWithImageOnRight;
