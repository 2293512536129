import React, { useRef, useState, useEffect } from "react";
import convertId from "../../utilities/convert-id";
import { graphql, useStaticQuery } from "gatsby";
import Content from "../utilities/content";
import Link from "../utilities/link";
import CmsImage from "../utilities/cms-image";
import Filter from "../utilities/filter";
import SsImage from "../utilities/ss-image";
import ProjectMap from "../utilities/project-map";

const OurProjects = ({ data }) => {
  const {
    allWpProductTag: { nodes: products },
    allWpSportTag: { nodes: sports },
    smartsheet: {
      projects: { projects },
    },
  } = useStaticQuery(graphql`
    query {
      allWpProductTag {
        nodes {
          slug
          name
        }
      }
      allWpSportTag {
        nodes {
          slug
          name
        }
      }
      smartsheet {
        projects {
          projects {
            id
            slug
            projectNumber
            organizationType
            projectName
            yearBuilt
            sportType
            projectType
            projectTypeNotes
            product
            icon
            projectAddress
            city
            state
            latitude
            longitude
            projectDescription
            youtubeVideoLink
            images {
              sourceUrl
            }
          }
        }
      }
    }
  `);

  // Filter Refs
  const primaryFilterRef = useRef(null),
    secondaryFilterRef = useRef(null);

  // Filter States
  const [activeSport, setActiveSport] = useState(null),
    [activeProduct, setActiveProduct] = useState(null);

  // Change Filters
  const changeFilter = (e, slug, active, setFunc) => {
    e.preventDefault();
    if (active === slug) {
      setFunc(null);
    } else {
      setFunc(slug);
    }
    setShowProjects(showProjectsBy - 1);

    primaryFilterRef.current.close();
    secondaryFilterRef.current.close();
  };

  // Pagination / Load More
  const showProjectsBy = 9;
  const [showProjects, setShowProjects] = useState(showProjectsBy - 1);

  const loadMoreProjects = () => {
    setShowProjects(showProjects + showProjectsBy);
  };

  // Projects Order
  let orderedProjects = [],
    nonImgProjects = [];

  projects.forEach((project) => {
    if (project.images?.length > 0) {
      orderedProjects = [...orderedProjects, project];
    } else {
      nonImgProjects = [...nonImgProjects, project];
    }
  });

  orderedProjects = [...orderedProjects, ...nonImgProjects];

  // Filter Projects
  const filterBy = (active, typeField) => {
    if (active) {
      orderedProjects = orderedProjects.filter((project) => {
        if (!project[typeField]) {
          return false;
        }

        const types = project[typeField].split(/(?:,|\/)+/).map((sport) => {
          return sport.trim();
        });

        let keep = false;

        types.forEach((type) => {
          if (type === active) {
            keep = true;
          }
        });

        return keep;
      });
    }
  };

  filterBy(activeSport, "sportType");
  filterBy(activeProduct, "projectType");

  const clearFilters = (e) => {
    if (e) e.preventDefault();
    setActiveSport(null);
    setActiveProduct(null);
  };

  // Projects or Map Showing?
  const [showType, setShowType] = useState("projects");

  const switchShowType = (e) => {
    e.preventDefault();
    let newShowType = showType === "projects" ? "map" : "projects";

    setShowType(newShowType);

    localStorage.setItem("default-project-type", newShowType);
  };

  useEffect(() => {
    let defaultProjectType = localStorage.getItem("default-project-type");

    if (defaultProjectType && defaultProjectType !== showType) {
      setShowType(defaultProjectType);
    }
  }, [showType]);

  return (
    <div
      id={convertId(data.name)}
      className="section-our-projects projects-page"
    >
      <section className="project-page-content">
        <div className="container">
          <div className="page-title page-title-alternate">
            <div className="row">
              <div className="col-lg-6 pe-lg-5 mb-5 mb-lg-0">
                <Content className="title">{data.title}</Content>
              </div>
              <div className="col-lg-6">
                <Content className="description">{data.description}</Content>
              </div>
            </div>
          </div>
          <div className="section">
            <Filter
              classMod="primary"
              fields={products}
              changeFunc={setActiveProduct}
              active={activeProduct}
              changeFilter={changeFilter}
              name="Project"
              ref={primaryFilterRef}
            />
            <Filter
              classMod="gray"
              fields={sports}
              changeFunc={setActiveSport}
              active={activeSport}
              changeFilter={changeFilter}
              name="Sport"
              ref={secondaryFilterRef}
            />
            <div className="text-center mb-5">
              <a
                href="#switch-display"
                onClick={(e) => switchShowType(e)}
                className="btn btn-projects"
              >
                Switch to {showType === "projects" ? "Map" : "Projects"} View
              </a>
            </div>
            <div className="row mb-3 mb-lg-5">
              {showType === "projects" ? (
                <>
                  {orderedProjects && orderedProjects.length > 0 ? (
                    orderedProjects.map((project, index) => {
                      if (index > showProjects) {
                        return;
                      }

                      return (
                        <div className="col-lg-4 project-col" key={project.id}>
                          <Link
                            to={`/project/${project.id}`}
                            className="mb-4 project-card"
                          >
                            <div className="project-card-overlay">
                              <div className="project-card-content">
                                <div className="project-card-content-text">
                                  <div className="thumbnail-title">
                                    <p>{project.projectName}</p>
                                    <p>{project.projectType}</p>
                                  </div>
                                </div>
                                <div className="project-card-content-cta">
                                  View Project
                                </div>
                              </div>
                            </div>
                            {project.images?.length > 0 ? (
                              <img
                                className="project-card-img"
                                src={project.images[0].sourceUrl}
                                alt={project.projectName}
                              />
                            ) : (
                              <>
                                {data.defaultProjectImage && (
                                  <CmsImage
                                    className="w-100 h-100"
                                    image={data.defaultProjectImage}
                                    alt={project.projectName}
                                  />
                                )}
                              </>
                            )}
                          </Link>
                        </div>
                      );
                    })
                  ) : (
                    <div className="content text-center">
                      <p className="text-center">
                        There are no projects for this combination of filters.{" "}
                        <a
                          href="#clear-filters"
                          onClick={(e) => clearFilters(e)}
                        >
                          Clear filters?
                        </a>
                      </p>
                    </div>
                  )}
                </>
              ) : (
                <ProjectMap projects={orderedProjects} />
              )}
            </div>
            {orderedProjects.length - 1 > showProjects &&
              showType === "projects" && (
                <div className="text-center">
                  <button
                    className="btn btn-projects"
                    onClick={loadMoreProjects}
                  >
                    Load More Projects
                  </button>
                </div>
              )}
          </div>
        </div>
      </section>
    </div>
  );
};

export const query = graphql`
  fragment OurProjects on WpPage_Sections_Sections_OurProjects {
    description
    fieldGroupName
    name
    title
    backgroundImage {
      altText
      localFile {
        extension
        publicURL
        childImageSharp {
          gatsbyImageData
        }
      }
    }
    defaultProjectImage {
      localFile {
        extension
        publicURL
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  }
`;

export default OurProjects;
