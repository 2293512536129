import React from "react";
import { graphql } from "gatsby";
import Builder from "../components/global/builder";

const Page = ({
  data: {
    wpPage: {
      title,
      page,
      sections: { sections },
    },
    wp: {
      themeSettings: { themeSettings },
    },
  },
}) => {
  return (
    <Builder
      title={title}
      page={page}
      sections={sections}
      themeSettings={themeSettings}
    />
  );
};

export const pageQuery = graphql`
  query Page($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      page {
        metaTitle
        metaDescription
        pageCssOverrides
      }
      sections {
        sections {
          ... on WpPage_Sections_Sections_QuoteColumnWithImage {
            ...QuoteColumnWithImage
          }
          ... on WpPage_Sections_Sections_CenteredContent {
            ...CenteredContent
          }
          ... on WpPage_Sections_Sections_CenteredContentWithBg {
            ...CenteredContentWithBg
          }
          ... on WpPage_Sections_Sections_ContentWithImage {
            ...ContentWithImage
          }
          ... on WpPage_Sections_Sections_ContactForm {
            ...ContactForm
          }
          ... on WpPage_Sections_Sections_PullQuoteBar {
            ...PullQuoteBar
          }
          ... on WpPage_Sections_Sections_Tagline {
            ...Tagline
          }
          ... on WpPage_Sections_Sections_Products {
            ...Products
          }
          ... on WpPage_Sections_Sections_HomeHero {
            ...HomeHero
          }
          ... on WpPage_Sections_Sections_Banner {
            ...Banner
          }
          ... on WpPage_Sections_Sections_TitleWithImageOnRight {
            ...TitleWithImageOnRight
          }
          ... on WpPage_Sections_Sections_TwoColumnsContent {
            ...TwoColumnsContent
          }
          ... on WpPage_Sections_Sections_ThreeColumnsServices {
            ...ThreeColumnsServices
          }
          ... on WpPage_Sections_Sections_ContentCta {
            ...ContentCta
          }
          ... on WpPage_Sections_Sections_TeamSection {
            ...TeamSection
          }
          ... on WpPage_Sections_Sections_ProductListing {
            ...ProductListing
          }
          ... on WpPage_Sections_Sections_OurProjects {
            ...OurProjects
          }
          ... on WpPage_Sections_Sections_JuicerSocialMedia {
            ...JuicerSocialMedia
          }
          ... on WpPage_Sections_Sections_ProjectsIframe {
            ...ProjectsIframe
          }
          ... on WpPage_Sections_Sections_Testimonials {
            ...Testimonials
          }
        }
      }
    }
    wp {
      themeSettings {
        themeSettings {
          siteMetaTitle
          customCssOverrides
        }
      }
    }
  }
`;

export default Page;
