import React from "react";
import convertId from "../../utilities/convert-id";
import { graphql } from "gatsby";
import CmsImage from "../utilities/cms-image";
import { getYoutubeId, getVideoUrl } from "../utilities/media";

const Banner = ({ data }) => {
  let youtubeId,
    videoUrl;

  if (data.bannerType === "youtube") {
    youtubeId = getYoutubeId(data.youtubeVideoLink, data.bannerType);
  } else if (data.bannerType === "mp4") {
    videoUrl = getVideoUrl(data.mp4VideoFile, data.bannerType);
  }

  return (
    <section id={convertId(data.name)} className="page-banner">
      {(!data.bannerType || data.bannerType === "image") &&
        <CmsImage
          className="page-banner-cover"
          image={data.image}
          alt={data.image.altText}
        />
      }
      {data.bannerType === "mp4" &&
        <video className="page-banner-video" autoPlay muted loop playsInline>
          <source src={videoUrl} type="video/mp4" />
        </video>
      }
      {data.bannerType === "youtube" &&
        <iframe className="page-banner-yt" src={`https://www.youtube.com/embed/${youtubeId}?autoplay=1&mute=1&controls=0&loop=1&modestbranding=1&playsinline=1&rel=0&playlist=${youtubeId}`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
      }
    </section>
  );
};

export const query = graphql`
  fragment Banner on WpPage_Sections_Sections_Banner {
    fieldGroupName
    name
    bannerType
    image {
      altText
      localFile {
        extension
        publicURL
        childImageSharp {
          gatsbyImageData
        }
      }
    }
    mp4VideoFile {
      localFile {
        publicURL
      }
      mediaItemUrl
    }
    youtubeVideoLink
  }
`;

export default Banner;
