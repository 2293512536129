import React from "react";
import convertId from "../../utilities/convert-id";
import { graphql } from "gatsby";
import Content from "../utilities/content";
import Link from "../utilities/link";

const ProjectsIframe = ({ data }) => {
  return (
    <section
      id={convertId(data.name)}
      className="section-our-projects projects-page projects-iframe"
    >
      <div className="container">
        <div className="page-title page-title-alternate">
          <div className="row">
            <div className="col-lg-6 pe-lg-5 mb-5 mb-lg-0">
              <Content className="title">{data.title}</Content>
            </div>
            <div className="col-lg-6">
              <Content className="description">{data.tagline}</Content>
            </div>
          </div>
        </div>
        <div className="text-center mb-5">
          <Link to={"/projects"} className="btn btn-projects">
            Switch to Projects View
          </Link>
        </div>
        <div className="projects-iframe-holder">
          <iframe className="projects-iframe-iframe" src={data.iframeUrl} />
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment ProjectsIframe on WpPage_Sections_Sections_ProjectsIframe {
    fieldGroupName
    name
    title
    tagline
    iframeUrl
  }
`;

export default ProjectsIframe;
