import React from "react";
import convertId from "../../utilities/convert-id";
import { graphql } from "gatsby";
import Content from "../utilities/content";

const Tagline = ({ data }) => {
  return (
    <section id={convertId(data.name)} className="section home-bigtext">
      <div className="container">
        <Content className="text-center text-white text-uppercase display-1 content-no-mb">
          {data.text}
        </Content>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment Tagline on WpPage_Sections_Sections_Tagline {
    fieldGroupName
    name
    text
  }
`;

export default Tagline;
