import React from "react";
import convertId from "../../utilities/convert-id";
import { graphql } from "gatsby";
import Content from "../utilities/content";

const ProductListing = ({ data }) => {
  return (
    <section id={convertId(data.name)} className="product-listing section">
      <div className="container">
        {data.products &&
          data.products.map((product, key) => (
            <div id={product.id} className="row align-items-center pb-5 mb-lg-5" key={product.id}>
              <div
                className={
                  "col-lg-6 " +
                  (product.contentPosition === "right"
                    ? "order-lg-1"
                    : "order-lg-2")
                }
              >
                <iframe
                  src={product.video}
                  title="YouTube video"
                  allowFullScreen
                  className="video"
                ></iframe>
              </div>
              <div
                className={
                  "col-lg-6 ps-lg-5 " +
                  (product.contentPosition === "right"
                    ? "order-lg-2"
                    : "order-lg-1")
                }
              >
                <Content className="product-content">{product.content}</Content>
              </div>
            </div>
          ))}
      </div>
    </section>
  );
};

export const query = graphql`
  fragment ProductListing on WpPage_Sections_Sections_ProductListing {
    fieldGroupName
    name
    products {
      id
      content
      contentPosition
      fieldGroupName
      video
    }
  }
`;

export default ProductListing;
