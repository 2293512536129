import React from "react";

export default class Filter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showFilter: false,
      height: "0",
    };

    this.navToggleRef = React.createRef();
  }

  toggleFilterDropdown = (e) => {
    e.preventDefault();

    this.setState({
      showFilter: !this.state.showFilter,
      height:
        this.state.height !== "0"
          ? "0"
          : this.navToggleRef.current.scrollHeight,
    });
  };

  close = () => {
    this.setState({
      showFilter: false,
      height: "0",
    });
  };

  render() {
    return (
      <div
        className={`p-4 p-lg-3 mb-4 project-filter project-filter-${this.props.classMod}`}
      >
        <a
          className="toggle-filter"
          href="#toggle-filter"
          onClick={(e) => this.toggleFilterDropdown(e)}
        >
          {this.props.active ? this.props.active : this.props.name + " Type"}
        </a>
        <ul
          className={`nav-toggle nav nav-pills flex-column flex-lg-row justify-content-center ${
            this.state.showFilter ? "active" : ""
          }`}
          style={{
            height: this.state.height ? this.state.height + "px" : "initial",
          }}
          ref={this.navToggleRef}
        >
          {this.props.fields &&
            this.props.fields.map((field) => (
              <li className="nav-item" key={field.slug}>
                <a
                  href={`#${field.slug}`}
                  onClick={(e) =>
                    this.props.changeFilter(
                      e,
                      field.name,
                      this.props.active,
                      this.props.changeFunc
                    )
                  }
                  className={this.props.active === field.name ? "active" : ""}
                >
                  {field.name}
                </a>
              </li>
            ))}
        </ul>
      </div>
    );
  }
}
