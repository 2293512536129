// You must add any sections you create to this file.

import CenteredContent from "../components/sections/centered-content";
import CenteredContentWithBg from "../components/sections/centered-content-with-bg";
import ContentWithImage from "../components/sections/content-with-image";
import ContactForm from "../components/sections/contact-form";
import PullQuoteBar from "../components/sections/pull-quote-bar";
import Tagline from "../components/sections/tagline";
import Products from "../components/sections/products";
import HomeHero from "../components/sections/home-hero";
import QuoteColumnWithImage from "../components/sections/quote-column-with-image";
import Banner from "../components/sections/banner";
import TitleWithImageOnRight from "../components/sections/title-with-image-on-right";
import TwoColumnsContent from "../components/sections/two-columns-content";
import ThreeColumnsServices from "../components/sections/three-columns-services";
import ContentCta from "../components/sections/content-cta";
import TeamSection from "../components/sections/team-section";
import ProductListing from "../components/sections/product-listing";
import OurProjects from "../components/sections/our-projects";
import JuicerSocialMedia from "../components/sections/juicer";
import ProjectsIframe from "../components/sections/projects-iframe";
import Testimonials from "../components/sections/testimonials";

const sectionComponents = {
  CenteredContent: CenteredContent,
  CenteredContentWithBg: CenteredContentWithBg,
  ContentWithImage: ContentWithImage,
  ContactForm: ContactForm,
  PullQuoteBar: PullQuoteBar,
  Tagline: Tagline,
  Products: Products,
  HomeHero: HomeHero,
  QuoteColumnWithImage: QuoteColumnWithImage,
  Banner: Banner,
  TitleWithImageOnRight: TitleWithImageOnRight,
  TwoColumnsContent: TwoColumnsContent,
  ThreeColumnsServices: ThreeColumnsServices,
  ContentCta: ContentCta,
  TeamSection: TeamSection,
  ProductListing: ProductListing,
  OurProjects: OurProjects,
  JuicerSocialMedia: JuicerSocialMedia,
  ProjectsIframe: ProjectsIframe,
  Testimonials: Testimonials
};

export default sectionComponents;
