import React from "react";
import { Helmet } from "react-helmet";
import convertId from "../../utilities/convert-id";
import { graphql } from "gatsby";

const JuicerSocialMedia = ({ data }) => {
  return (
    <section id={convertId(data.name)} className="section-news-social">
      <Helmet>
        <script
          src="https://assets.juicer.io/embed.js"
          type="text/javascript"
        ></script>
        <link
          href="https://assets.juicer.io/embed.css"
          media="all"
          rel="stylesheet"
          type="text/css"
        />
      </Helmet>
      <div className="container">
        <ul class="juicer-feed" data-feed-id="symmetry_sports"></ul>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment JuicerSocialMedia on WpPage_Sections_Sections_JuicerSocialMedia {
    fieldGroupName
    name
  }
`;

export default JuicerSocialMedia;
