import React from "react";
import convertId from "../../utilities/convert-id";
import { graphql } from "gatsby";
import Content from "../utilities/content";
import Link from "../utilities/link";
import arrowDouble from "../../images/arrow-double.svg";
import CmsImage from "../utilities/cms-image";
import CmsLink from "../utilities/cms-link";

const ProductLogo = ({ logo }) => {
  return (
    <CmsImage
      image={logo.logo}
      alt={logo.logo.altText}
      className="img-fluid"
    />
  );
}

const Products = ({ data }) => {
  return (
    <section id={convertId(data.name)} className="section-home-products">
      <div className="container">
        <div className="section-group">
          <div className="row justify-content-center" data-aos="fade-up">
            <div className="col-lg-8">
              <div className="row">
                <div className="col-lg-6">
                  <Content className="product-home-title">
                    {data.content}
                  </Content>
                  <Link className="btn-link" to={data.buttonLink.url}>
                    {data.buttonLink.title}
                    <CmsImage
                      image={arrowDouble}
                      alt={data.buttonLink.title}
                      className="ms-2"
                    />
                  </Link>
                </div>
                <div className="col-lg-6 text-center prod-img-wrapper">
                  {data.logos &&
                    data.logos.map((logo, key) => (
                      <div className="product-home-img" key={key}>
                        {logo.link ? (
                          <CmsLink className="d-block" link={logo.link}>
                            <ProductLogo logo={logo} />
                          </CmsLink>
                        ) : (
                          <ProductLogo logo={logo} />
                        )}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment Products on WpPage_Sections_Sections_Products {
    content
    fieldGroupName
    name
    logos {
      logo {
        altText
        localFile {
          extension
          publicURL
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      link {
        target
        title
        url
      }
    }
    buttonLink {
      target
      title
      url
    }
  }
`;

export default Products;
