import React from "react";
import convertId from "../../utilities/convert-id";
import { graphql } from "gatsby";
import Content from "../utilities/content";
import Link from "../utilities/link";
import CmsImage from "../utilities/cms-image";
import convertLink from "../../utilities/convert-link";

const ThreeColumnsServices = ({ data }) => {
  return (
    <section
      id={convertId(data.name)}
      className="section-mini section-three-column-services"
    >
      <div className="container-fluid text-center">
        <div className="row justify-content-center">
          {data.columns &&
            data.columns.map((item, key) => (
              <div className="col-lg-4 three-column" key={key}>
                <Link
                  to={convertLink(item.link)}
                  className="home-feature mb-4 mb-lg-0"
                >
                  <CmsImage className="home-featured-bg" image={item.image} />
                  <Content className="text-overlay">{item.content}</Content>
                </Link>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment ThreeColumnsServices on WpPage_Sections_Sections_ThreeColumnsServices {
    fieldGroupName
    name
    columns {
      link {
        target
        title
        url
      }
      content
      image {
        altText
        localFile {
          childImageSharp {
            gatsbyImageData
          }
          extension
          publicURL
        }
      }
    }
  }
`;

export default ThreeColumnsServices;
