import React, {useEffect, useRef} from "react";
import convertId from "../../utilities/convert-id";
import { graphql } from "gatsby";
import Content from "../utilities/content";
import CmsLink from "../utilities/cms-link";

const Quote = ({ quote }) => {
  return (
    <Content className="text-carousel-quote">
      {quote.quote}
    </Content>
  );
}

const PullQuoteBar = ({ data }) => {
  const textCarousel = useRef(null);

  useEffect(() => {
    const bootstrap = require('bootstrap');

    if (textCarousel && bootstrap) {
      const bsTextCarousel = bootstrap.Carousel.getOrCreateInstance(textCarousel.current);
      if (bsTextCarousel) {
        bsTextCarousel.cycle();
      }
    }
  })

  return (
    <section
      id={convertId(data.name)}
      className="section section-text-carousel"
    >
      <div className="container">
        <div
          id="textCarousel"
          className="carousel slide"
          data-bs-ride="carousel"
          data-bs-interval="6000"
          data-bs-pause="false"
          ref={textCarousel}
        >
          <div className="carousel-inner">
            {data.quotes &&
              data.quotes.map((quote, key) => (
                <div
                  className={`carousel-item ${key === 0 ? "active" : ""}`}
                  key={key}
                >
                  {data.link ? (
                    <CmsLink className="d-block" link={data.link}>
                      <Quote quote={quote} />
                    </CmsLink>
                  ) : (
                    <Quote quote={quote} />
                  )}
                </div>
              ))}
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#textCarousel"
            data-bs-slide="prev"
          >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#textCarousel"
            data-bs-slide="next"
          >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment PullQuoteBar on WpPage_Sections_Sections_PullQuoteBar {
    fieldGroupName
    name
    link {
      target
      title
      url
    }
    quotes {
      quote
    }
  }
`;

export default PullQuoteBar;
