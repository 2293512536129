import React from "react";
import convertId from "../../utilities/convert-id";
import { graphql, useStaticQuery } from "gatsby";
import Content from "../utilities/content";

const Testimonials = ({ data }) => {
  const {
    smartsheet: {
      testimonials: { testimonials },
    },
  } = useStaticQuery(graphql`
    query {
      smartsheet {
        testimonials {
          testimonials {
            testimonial
            companyIsd
            customerName
            title
            obtainedBy
            dateReceived
          }
        }
      }
    }
  `);

  return (
    <section
      id={convertId(data.name)}
      className="section testimonials"
      style={
        data.backgroundImage.localFile !== null
          ? {
              background: `url(${data.backgroundImage.localFile.publicURL}) no-repeat center center `,
            }
          : {}
      }
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-9">
            {data.heading && (
              <Content className="centered-title">{data.heading}</Content>
            )}
            <div className="testm-row d-flex flex-column-reverse">
              {testimonials &&
                testimonials.map((testim) => (
                  <div className="testm-single" key={testim.customerName}>
                    <div className="content testm-single-content">
                      {testim.companyIsd && <h3>{testim.companyIsd}</h3>}
                      <p>
                        <span>
                          {testim.testimonial}
                          <br />
                          <span className="source">
                            — {testim.customerName}, {testim.title}
                          </span>
                        </span>
                      </p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment Testimonials on WpPage_Sections_Sections_Testimonials {
    fieldGroupName
    name
    backgroundImage {
      altText
      localFile {
        childImageSharp {
          gatsbyImageData
        }
        extension
        publicURL
      }
    }
    heading
  }
`;

export default Testimonials;
