import React from "react";
import convertId from "../../utilities/convert-id";
import { graphql } from "gatsby";
import Content from "../utilities/content";

const CenteredContent = ({ data }) => {
  return (
    <>
      <section id={convertId(data.name)} className="section ">
        <div className="container">
          <Content className="centered-content">{data.content}</Content>
          {data.form === "contact" && (
            <iframe className="jotform jotform-contact" src="https://form.jotform.com/221656411858157" />
          )}
        </div>
      </section>
    </>
  );
};

export const query = graphql`
  fragment CenteredContent on WpPage_Sections_Sections_CenteredContent {
    fieldGroupName
    name
    content
    form
  }
`;

export default CenteredContent;
