import React from "react";
import convertId from "../../utilities/convert-id";
import { graphql } from "gatsby";
import CmsImage from "../utilities/cms-image";
import Content from "../utilities/content";
import { getYoutubeId, getVideoUrl } from "../utilities/media";

const ContentWithImage = ({ data }) => {
  let youtubeId,
    videoUrl;

  if (data.mediaType === "youtube") {
    youtubeId = getYoutubeId(data.youtubeVideoLink, data.mediaType);
  } else if (data.mediaType === "mp4") {
    videoUrl = getVideoUrl(data.mp4VideoFile, data.mediaType);
  }
  
  return (
    <section className="section">
      <div id={convertId(data.name)} className="container mx-auto">
        <div className="row">
          <div className="col-md">
            <Content className="content">{data.content}</Content>
          </div>
          {data.mediaType !== 'none' && (
            <div className="col-md">
              {(!data.mediaType || data.mediaType === "image") &&
                <CmsImage className="rounded img-fluid" image={data.image} />
              }
              {data.mediaType === "mp4" &&
                <video className="rounded img-fluid cwi-video" controls autoPlay muted loop playsInline>
                  <source src={videoUrl} type="video/mp4" />
                </video>
              }
              {data.mediaType === "youtube" && (
                <div className="ratio ratio-16x9">
                  <iframe loading="lazy" className="rounded img-fluid cwi-iframe" src={`https://www.youtube.com/embed/${youtubeId}?autoplay=1&mute=1&loop=1&playlist=${youtubeId}`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                </div>
                )
              }
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment ContentWithImage on WpPage_Sections_Sections_ContentWithImage {
    fieldGroupName
    name
    content
    mediaType
    image {
      altText
      localFile {
        childImageSharp {
          gatsbyImageData
        }
        extension
        publicURL
      }
    }
    mp4VideoFile {
      localFile {
        publicURL
      }
    }
    youtubeVideoLink
  }
`;

export default ContentWithImage;
