import React, { useState } from "react";
import convertId from "../../utilities/convert-id";
import { graphql } from "gatsby";
import CmsImage from "../utilities/cms-image";
import Content from "../utilities/content";

const TeamSection = ({ data }) => {
  const [memberModal, setMemberModal] = useState(null);
  const populateModal = (param) => {
    setMemberModal(param);
  };
  return (
    <section
      id={convertId(data.name)}
      className="team-section section section-t-0"
    >
      <div className="container">
        <Content className="title">{data.title}</Content>
        <div className="row">
          {data.members &&
            data.members.map((member, key) => (
              <div
                className="col-lg-4"
                key={key}
                tabIndex={key}
                data-bs-toggle="modal"
                data-bs-target="#modalTeam"
                onClick={() => populateModal(member)}
                onKeyDown={() => populateModal(member)}
                role="button"
              >
                <div className="mb-5 team-wrapper">
                  {member.image ? (
                    <CmsImage
                      image={member.image}
                      alt={member.image.altText}
                      className="team-image rounded img-fluid"
                    />
                  ) : (
                    <div
                      className="team-image rounded img-fluid"
                      style={{ background: "#393D34" }}
                    ></div>
                  )}
                  <Content className="name-position">
                    {member.nameAndPosition}
                  </Content>
                </div>
              </div>
            ))}
        </div>
      </div>
      <div
        className="modal modal-fade modal-team-section"
        id="modalTeam"
        tabIndex="-1"
        aria-labelledby="modalTeamLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-fullscreen">
          <div className="modal-content">
            <div className="modal-header pb-0 border-bottom-0 justify-content-end">
              <button
                type="button"
                className="btn-close-text font-proxima-nova fw-bold b-0"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                X Close
              </button>
            </div>
            <div className="modal-body">
              {memberModal && (
                <div className="row align-items-center justify-content-center">
                  <div className="col-lg-5 text-center">
                    {memberModal.image ? (
                      <CmsImage
                        image={memberModal.image}
                        alt={memberModal.image.altText}
                        className="team-image rounded mb-4 mb-lg-0"
                      />
                    ) : (
                      <div
                        className="team-image rounded mb-4 mb-lg-0"
                        style={{ background: "#393D34" }}
                      ></div>
                    )}
                  </div>
                  <div className="col-lg-5 content-wrapper">
                    <Content className="name-position-modal">
                      {memberModal.nameAndPosition}
                    </Content>
                    <Content className="content-modal">
                      {memberModal.content}
                    </Content>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment TeamSection on WpPage_Sections_Sections_TeamSection {
    fieldGroupName
    name
    hideSection
    title
    members {
      nameAndPosition
      content
      image {
        altText
        localFile {
          childImageSharp {
            gatsbyImageData
          }
          extension
          publicURL
        }
      }
    }
  }
`;

export default TeamSection;
