import React from "react";
import convertId from "../../utilities/convert-id";
import { graphql } from "gatsby";

const ContactForm = ({ data }) => {
  return (
    <section id={convertId(data.name)} className="section">
      <div className="container mx-auto">
        <iframe
          className="jotform jotform-contact-footer"
          src="https://form.jotform.com/222163757174155"
        />
      </div>
    </section>
  );
};

export const query = graphql`
  fragment ContactForm on WpPage_Sections_Sections_ContactForm {
    fieldGroupName
    name
  }
`;

export default ContactForm;
