import React from "react";
import convertId from "../../utilities/convert-id";
import { graphql } from "gatsby";
import Content from "../utilities/content";

const CenteredContentWithBg = ({ data }) => {
  return (
    <section
      id={convertId(data.name)}
      className="section centered-content-with-bg"
      style={
        data.backgroundImage.localFile !== null
          ? {
              background: `url(${data.backgroundImage.localFile.publicURL}) no-repeat center center `,
            }
          : {}
      }
    >
      <div className="container text-center">
        <div className="row justify-content-center">
          <div className="col-lg-9">
            {data.title && (
              <Content className="centered-title">{data.title}</Content>
            )}
            <Content className="centered-content-bg">{data.content}</Content>
          </div>
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment CenteredContentWithBg on WpPage_Sections_Sections_CenteredContentWithBg {
    fieldGroupName
    name
    title
    content
    backgroundImage {
      altText
      localFile {
        childImageSharp {
          gatsbyImageData
        }
        extension
        publicURL
      }
    }
  }
`;

export default CenteredContentWithBg;
