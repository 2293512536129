import React from "react";
import convertId from "../../utilities/convert-id";
import { graphql } from "gatsby";

import CmsImage from "../utilities/cms-image";
import Content from "../utilities/content";

const QuoteColumnWithImage = ({ data }) => {
  return (
    <section id={convertId(data.name)} className="section-qoute-column-image">
      <div className="container">
        <div className="quote-box d-block d-lg-flex justify-content-center align-items-center">
          <div className="quote-box-image" data-aos="fade-right">
            <CmsImage image={data.image} className="rounded img-fluid" />
          </div>
          <div
            className="quote-box-content rounded overflow-hidden"
            data-aos="fade-left"
          >
            <Content
              className="content content-box-q"
              data-aos="fade-left"
              data-aos-delay="50"
            >
              {data.content}
            </Content>
            <div className="quote-box-author">
              <div className="d-flex align-items-end">
                <div
                  className="flex-shrink-0"
                  data-aos="fade-left"
                  data-aos-delay="60"
                >
                  <CmsImage image={data.imageSub} className="rounded" />
                </div>
                <div className="flex-grow-1 ms-4">
                  <Content
                    className="sub-content"
                    data-aos="fade-left"
                    data-aos-delay="70"
                  >
                    {data.contentSub}
                  </Content>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment QuoteColumnWithImage on WpPage_Sections_Sections_QuoteColumnWithImage {
    fieldGroupName
    name
    content
    image {
      altText
      localFile {
        childImageSharp {
          gatsbyImageData
        }
        extension
        publicURL
      }
    }
    contentSub
    imageSub {
      altText
      localFile {
        childImageSharp {
          gatsbyImageData
        }
        extension
        publicURL
      }
    }
  }
`;

export default QuoteColumnWithImage;
